import React from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { StaticQuery, graphql } from 'gatsby';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import { handleSchedule } from 'helpers/trainingHelper';
import {
  DataContainerSkeleton,
  LoadingSkeleton,
  LoadingImageSkeleton,
} from '@sections/ResourcesCenterHero/resourcesCenterHero.styles';
import { WebinarIndexHeroProps } from './webinarIndexHero.types';
import CalendarIcon from './assets/calendar.svg';

import {
  WebinarContainer,
  WebinarTitle,
  WebinarWrapper,
  Container,
  DataContainer,
  ImageContainer,
  TitleContainer,
  AllWebinarsContainer,
  AllWebinarTitle,
  AllWebinarText,
  AllWebinarCTA,
  HeroWrapper,
  CalendarContainer,
  WebinarSchedule,
  WebinarTitleContainer,
  MainContainer,
  PrincipalTopic,
  Tag,
} from './webinarIndexHero.styles';

const WebinarIndexHeroComponent = ({ data }: WebinarIndexHeroProps) => {
  const spotlightWebinars = data.cms.webinarPosts.data.slice(0, 3);

  const skeletonComponent = () => {
    return Array.from({ length: 3 }, () => (
      <WebinarWrapper>
        <DataContainerSkeleton className="data-container">
          <Tag>
            <LoadingSkeleton />
            <LoadingSkeleton />
          </Tag>
          <WebinarTitleContainer>
            <LoadingSkeleton count={2} isFirstLineSkeleton className="secondary-title" />
          </WebinarTitleContainer>
          <LoadingSkeleton />
        </DataContainerSkeleton>
        <ImageContainer>
          <LoadingImageSkeleton />
        </ImageContainer>
      </WebinarWrapper>
    ));
  };

  return (
    <Container>
      <GlobalWrapper>
        <TitleContainer>Webinar</TitleContainer>
        <HeroWrapper>
          <WebinarContainer>
            {!data && spotlightWebinars.length === 0
              ? skeletonComponent().map((component) => component)
              : spotlightWebinars.map((webinarPost, index) => (
                  <WebinarWrapper
                    key={webinarPost.attributes.Title}
                    href={`${PATHS.WEBINAR}/${transformToSlug(
                      webinarPost.attributes.slug.toLowerCase(),
                    )}`}
                    isLastWebinar={index === 2}
                  >
                    <DataContainer className="data-container">
                      <Tag>Webinar</Tag>
                      <WebinarSchedule>
                        {handleSchedule(webinarPost.attributes.Schedule, true)}
                      </WebinarSchedule>
                      <WebinarTitleContainer>
                        <WebinarTitle>{webinarPost.attributes.Title}</WebinarTitle>
                        <PrincipalTopic
                          onClick={() =>
                            (window.location.href = `${PATHS.WEBINAR}/?filter=${webinarPost.attributes.Primary_Field}`)
                          }
                        >
                          {webinarPost.attributes.Primary_Field}
                        </PrincipalTopic>
                      </WebinarTitleContainer>
                    </DataContainer>
                    <ImageContainer>
                      {CMSParseImage(webinarPost.attributes.Small_Image)}
                    </ImageContainer>
                  </WebinarWrapper>
                ))}
            <MainContainer>
              <AllWebinarsContainer>
                <AllWebinarTitle>Attend a Live, Virtual Webinar</AllWebinarTitle>
                <AllWebinarText>Check out our training calendar and register.</AllWebinarText>
                <AllWebinarCTA href={`${PATHS.CALENDAR}?type=webinar`}>
                  View all calendar
                </AllWebinarCTA>
              </AllWebinarsContainer>
              <CalendarContainer src={CalendarIcon} />
            </MainContainer>
          </WebinarContainer>
        </HeroWrapper>
      </GlobalWrapper>
    </Container>
  );
};

const WebinarIndexHero = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          cms {
            webinarPosts(filters: { Spotlight_Webinar: { eq: true } }) {
              data {
                attributes {
                  slug
                  Title
                  Schedule
                  Small_Image {
                    data {
                      attributes {
                        alternativeText
                        url
                        mime
                        urlJSON
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => <WebinarIndexHeroComponent data={data} />}
    />
  );
};

export default WebinarIndexHero;
