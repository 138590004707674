/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import NoResultFilter from '@components/NoResultFilter/NoResultFilter';
import { handleFilterArticles } from 'helpers/resourceCenterHelper';
import CalendarIcon from './assets/calendar.svg';
import {
  Container,
  DataContainer,
  ImageContainer,
  SubtitleContainer,
  Tag,
  TitleContainer,
  Wrapper,
  AllWebinarContainer,
  AllWebinarTitle,
  SeparateLine,
  CalendarContainer,
  AllWebinarText,
  AllWebinarCTA,
  WebinarDate,
  CollectionClearFilter,
  WebinarContainer,
  WebinarSchedule,
  TopicContainer,
  WebinarTopic,
} from './webinarList.styles';
import { WebinarPostsListProps, WebinarsProps } from './WebinarList.types';

const WebinarPostsList = ({ webinars }: WebinarPostsListProps) => {
  const [filterApplied, setFilterApplied] = useState<string[]>([]);
  const [renderWebinars, setRenderWebinars] = useState(webinars);

  const handleSchedule = (schedule: string) => {
    const scheduleDate = new Date(schedule);
    const scheduleHours = scheduleDate.getHours();
    const scheduleMinutes = scheduleDate.getMinutes();
    const scheduleWeekDate = scheduleDate.toLocaleString('en-us', { weekday: 'long' });

    if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
      return (
        <>
          {scheduleDate
            .toLocaleString('default', {
              month: 'short',
            })
            .toUpperCase()}{' '}
          {scheduleDate.getDate()}
          <WebinarDate>
            <SeparateLine>|</SeparateLine>
            {scheduleWeekDate}
            <SeparateLine>|</SeparateLine>
            {scheduleHours > 10 ? scheduleHours : `0${scheduleHours}`}:
            {scheduleMinutes > 10 ? scheduleMinutes : `0${scheduleMinutes}`}
          </WebinarDate>
        </>
      );
    }
    return <WebinarDate>{schedule}</WebinarDate>;
  };

  const handleFilterViaQueryParam = (urlParams: URLSearchParams) => {
    const topic = urlParams.get('topic');
    const role = urlParams.get('role');
    const career = urlParams.get('career');
    const type = urlParams.get('types');
    if (topic) {
      setFilterApplied([topic]);
    }
    if (role) {
      setFilterApplied([role]);
    }
    if (career) {
      setFilterApplied([career]);
    }
    if (type) {
      setFilterApplied([type]);
    }
  };

  useEffect(() => {
    if (filterApplied.length > 0) {
      setRenderWebinars(handleFilterArticles(webinars as WebinarsProps[], filterApplied));
    } else {
      setRenderWebinars(webinars);
    }
  }, [webinars, filterApplied]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    handleFilterViaQueryParam(urlParams);
  }, []);
  return (
    <GlobalWrapper>
      <Container>
        {filterApplied.length > 0 && (
          <CollectionClearFilter onClick={() => setFilterApplied([])}>
            Clear Filter
          </CollectionClearFilter>
        )}
        <Wrapper>
          {renderWebinars.length > 0 ? (
            renderWebinars.map((webinarPosts, index) => (
              <>
                <WebinarContainer
                  key={webinarPosts.attributes.Title}
                  href={`${PATHS.WEBINAR}/${transformToSlug(
                    webinarPosts.attributes.slug.toLowerCase(),
                  )}`}
                >
                  <ImageContainer>
                    <Tag>Webinar</Tag>
                    {CMSParseImage(webinarPosts.attributes.Small_Image.data)}
                  </ImageContainer>
                  <DataContainer className="webinar-post-data">
                    <WebinarSchedule>
                      {handleSchedule(webinarPosts.attributes.Schedule)}
                    </WebinarSchedule>
                    <TitleContainer className="webinar-posts-title">
                      {webinarPosts.attributes.Title}
                    </TitleContainer>
                    <SubtitleContainer>{webinarPosts.attributes.Subtitle}</SubtitleContainer>
                    <TopicContainer>
                      <WebinarTopic
                        onClick={() =>
                          (window.location.href = `${PATHS.WEBINAR}/?filter=${
                            webinarPosts.attributes?.Primary_Field || ''
                          }`)
                        }
                      >
                        {webinarPosts.attributes?.Primary_Field}
                      </WebinarTopic>
                    </TopicContainer>
                  </DataContainer>
                </WebinarContainer>
                {index === 4 && (
                  <AllWebinarContainer>
                    <CalendarContainer src={CalendarIcon} />
                    <AllWebinarTitle>Attend a Live, Virtual Webinar</AllWebinarTitle>
                    <AllWebinarText>Check out our webinar calendar and register.</AllWebinarText>
                    <AllWebinarCTA href={`${PATHS.CALENDAR}?type=webinar`}>
                      View all calendar
                    </AllWebinarCTA>
                  </AllWebinarContainer>
                )}
              </>
            ))
          ) : (
            <NoResultFilter />
          )}
        </Wrapper>
      </Container>
    </GlobalWrapper>
  );
};

export default WebinarPostsList;
