import { Image } from '@global/types';

export interface ResourcesCenterHeroProps {
  data: {
    trainings: SpotlightTrainingProps[];
    materials: SpotlightMaterialsProps[];
    blogs: SpotlightMaterialsProps[];
    caseStudies: SpotlightCaseStudiesProps[];
  };
}
export interface SpotlightMaterialsProps {
  attributes: {
    slug: string;
    Title: string;
    Publication_Date: string;
    Small_Image: Image;
    Time_To_Read: string;
    Subtitle: string;
    Primary_Field: string;
    Primary_Role: { data: { attributes: { Role: string } } };
    Tag: { data: { attributes: { TextColor: string; Type: string } } };
  };
}

export interface SpotlightTrainingProps {
  attributes: {
    slug: string;
    Title: string;
    Subtitle: string;
    Schedule: string;
    Small_Image: Image;
    Primary_Field: string;
    Primary_Role: { data: { attributes: { Role: string } } };
  };
}

export interface SpotlightCaseStudiesProps {
  attributes: {
    slug: string;
    Title: string;
    Publication_Date: string;
    Small_Image: Image;
    Time_To_Read: string;
    Subtitle: string;
    Primary_Field: string;
    Secondary_Field: string;
  };
}

export enum ArticleType {
  Material,
  Training,
  WebinarPost,
  CaseStudies,
  Blog,
}
